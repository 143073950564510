*:focus,
.p-focus {
  box-shadow: none !important;
}

small {
  font-size: 13px !important;
}

.color-white {
  color: #ffffff !important;
}

.color-black {
  color: #333333 !important;
}

a.color-black:hover,
a.greytext:hover {
  color: #86aede !important;
}

.color-teal {
  color: #00b5ad !important;
}

.color-blue {
  color: #2185d0 !important;
}

.bg-light-grey {
  background: #f9f9f9 !important;
}

.bg-teal {
  background: #00b5ad !important;
}

.bg-light-green {
  background: #b6ff71 !important;
}

.bg-light-red {
  background: #cd7475 !important;
}

.color-green {
  color: #34a835 !important;
}

.color-red {
  color: #e91224 !important;
}

.p-button-label {
  font-weight: 500;
  font-family: 'Avenir Next Cyr', 'Helvetica Neue', sans-serif !important;
}

.p-component,
.p-inputtext {
  font-weight: normal;
  font-family: 'Avenir Next Cyr', 'Helvetica Neue', sans-serif !important;
}

input,
.p-inputtextarea {
  border-radius: 0 !important;
}

.p-inputgroup-addon {
  border-radius: 0 !important;

  &:last-child {
    border-left: none !important;
  }
}

.p-calendar {
  .p-datepicker {
    min-width: 300px !important;

    table td > span.p-highlight {
      color: #000000;
      background: #cfe9fd;
    }
  }
}

.p-datepicker table td {
  padding: 0.1rem !important;
}

.p-menubar.tabs-bar {
  padding: 0;
  border: none;

  p-menubarsub {
    min-width: 200px !important;
    z-index: 999;
  }

  .p-menuitem {
    width: 100%;

    &:not(:last-child) .p-menuitem-link {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:first-child) .p-menuitem-link {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .p-menuitem-link {
    background: #83a8ce !important;
    padding: 1rem !important;

    &:hover {
      background: #8baed2 !important;
    }
  }

  .p-menuitem-link.p-menuitem-link-active,
  .p-menuitem-link-active > a.p-menuitem-link {
    background: #7596b9 !important;
  }

  .p-menuitem-text {
    margin: 0 auto;
    font-weight: 700;
    font-family: Lato, 'Helvetica Neue', sans-serif !important;
    font-size: 15px !important;
    color: #fff !important;
    white-space: nowrap;
  }

  .p-submenu-icon {
    color: #fff !important;
  }

  .p-submenu-list {
    min-width: 100%;
  }

  @media screen and (max-width: 960px) {
    background: none;
    .p-menubar-button {
      i {
        font-size: 1.5rem;
      }

      &:hover {
        background: none;
      }
    }
    .p-submenu-icon {
      margin-left: 0 !important;
    }
  }
}

.p-menubar.tabs-bar-new {
  padding: 0;
  border: none;
  border-radius: 3px;

  p-menubarsub {
    min-width: 200px !important;
    z-index: 999 !important;
  }

  .p-menuitem-link {
    margin: 0.4rem 0.2rem !important;
    padding: 0.6rem 0.7rem !important;
    border-radius: 7px !important;

    &:hover {
      background: #545e73 !important;

      .p-menuitem-text,
      .p-submenu-icon {
        color: white !important;
      }
    }
  }

  .p-menuitem-link.p-menuitem-link-active,
  .p-menuitem-link-active > a.p-menuitem-link {
    background: #3f485a !important;

    .p-menuitem-text,
    .p-submenu-icon {
      color: white !important;
    }
  }

  .p-menuitem-text {
    margin: 0;
    font-weight: 700;
    font-family: Lato, 'Helvetica Neue', sans-serif !important;
    font-size: 15px !important;
    color: #334155 !important;
    white-space: nowrap;
  }

  .p-submenu-icon {
    color: #334155 !important;
  }

  .p-submenu-list {
    min-width: 100%;
    padding: 0 !important;
  }

  @media screen and (max-width: 960px) {
    background: none;
    border: none;
    .p-menubar-button {
      i {
        font-size: 1.5rem;
      }

      &:hover {
        background: none;
      }
    }
    .p-submenu-icon {
      margin-left: 0.4rem !important;
    }
  }
}

.p-menubar.manager {
  padding: 0;
  border: none;
  background: rgba(0, 0, 0, 0);

  .p-menuitem:not(:last-child) {
    margin-right: 0.5rem;
  }

  .p-menuitem-text {
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.95) !important;
    font-size: 15px !important;
  }
}

.p-card.manager {
  background: linear-gradient(#d8ebf8, #d0e3ef);
  border: 1px solid #72a6de;

  .p-card-content {
    color: #3b4a8b;

    a {
      color: #4c4c4c;
      text-decoration: underline;
    }
  }
}

.p-card.manager-important {
  background: linear-gradient(#ffebeb, #ffd6d6);
  border: 1px solid #ff7979;

  .p-card-content {
    color: #da6565;
  }
}

.p-card.managerIssue {
  background: #fff4d9;
  border: 1px solid #743535;

  .p-card-content {
    color: #8b643b;

    a {
      color: #4c4c4c;
      text-decoration: underline;
    }
  }
}

.p-datatable.reglament {
  border: 1px solid #dbdbdb !important;
}

.p-multiselect,
.p-dropdown {
  border-radius: 0 !important;

  .p-checkbox .p-checkbox-box {
    border-color: #a6a6a6 !important;

    &.p-highlight {
      background: #737373;

      &:hover {
        background: #878c90;
      }
    }
  }
}

.p-multiselect-panel {
  left: 0 !important;
  max-width: 100%;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  border-bottom: 1px solid #ececec !important;
  font-size: 14px !important;
  font-family: 'Avenir Next Cyr', 'Helvetica Neue', sans-serif !important;
  font-weight: 500;
  padding: 0.82rem 0.9rem !important;
  white-space: normal;

  &.p-highlight {
    color: #000 !important;
    background: #f1f7d8 !important;
  }
}

.p-multiselect-panel .p-multiselect-items > :last-child .p-multiselect-item,
.p-dropdown-panel .p-dropdown-items > :last-child .p-dropdown-item {
  border-bottom: none !important;
}

.p-float-label label {
  font-size: 1rem !important;
}

.p-calendar > input.text-datepicker-input {
  width: 220px;
  border: none;
  text-align: center;
  font-weight: 700;
  font-family: Lato, 'Helvetica Neue', sans-serif !important;
  font-size: 24px;
  color: #4a9aff;
  cursor: pointer;
}

.p-tooltip {
  max-width: 20rem !important;

  .p-tooltip-text {
    white-space: normal !important;
  }
}

.p-dropdown.icon-dropdown {
  background: transparent;
  border: none;

  .p-dropdown-label {
    display: none;
  }

  .p-dropdown-trigger {
    color: #000;
    width: auto;
    padding: 0.15rem;
  }
}

.p-overlaypanel {
  &.dropdown-overlay-panel {
    .p-overlaypanel-content {
      padding: 0;
    }
  }

  &.informer-overlay-panel {
    position: fixed;
    display: block !important;
    top: unset !important;
    bottom: 40px;

    &::before,
    &::after {
      content: none;
    }
  }
}

.p-card {
  //box-shadow: none !important;
  border: 1px solid #e0e0e0;
  color: #212121;
}

.p-card-content {
  padding: 0 !important;
}

.p-datatable.sticky-header {
  position: relative;

  .p-datatable-thead {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.p-datatable {
  .p-datatable-thead > tr > th,
  .p-datatable-tfoot > tr > td {
    color: #333333;
    background: #f5f5f5 !important;
  }

  .p-datatable-tbody > tr {
    color: #333333;
  }
}

.borderless-table {
  tr {
    td {
      border: none !important;
    }

    &:not(:last-child) td {
      border-bottom: 1px solid #2224261a !important;
    }
  }
}

.task-fields-table {
  tr:first-child td:first-child {
    width: 50%;
  }
}

.p-divider.p-divider-horizontal {
  height: 0.01px;

  &:before {
    border-top: 1px solid #2224261a !important;
  }
}

.revenue-year-menu {
  .p-tabmenuitem {
    .p-menuitem-link {
      .p-menuitem-text {
        font-size: 18px;
      }
    }
  }
}

.p-tabmenu {
  overflow-x: initial !important;

  .p-tabmenu-nav {
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      border: none;
    }
  }

  .p-tabmenuitem {
    .p-menuitem-link {
      background: none !important;

      .p-menuitem-text {
        font-weight: 500 !important;
        font-family: Lato, 'Helvetica Neue', sans-serif !important;
      }
    }

    @media screen and (max-width: 768px) {
      flex-grow: 1;
      flex-basis: 0;
      margin: 2px !important;

      .p-menuitem-link {
        justify-content: center;
        height: 100%;

        .p-menuitem-text {
          white-space: nowrap;
        }
      }
    }
  }
}

.p-message.p-message-info {
  background: #d8eeff !important;
  color: #072682 !important;
}

.stats-up,
.p-datatable.basic-table td.stats-up {
  border-left: 2px solid #9acd32 !important;
}

.stats-down,
.p-datatable.basic-table td.stats-down {
  border-left: 2px solid #cd5c5c !important;
}

.p-menuitem-link-active {
  background: rgba(0, 0, 0, 0.05);
}

.step-label {
  min-width: 40px;
  min-height: 40px;
  border: 2px solid #cecece;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;

  &.active {
    color: white;
    background: #4a9aff;
    border-color: #596fce;
  }
}

.p-avatar img {
  object-fit: cover;
}

.responsive-table .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 768px) {
  .p-datatable {
    &.responsive-table {
      .p-datatable-thead > tr > th:not(.columns-filter),
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid #cccccc;
      }

      .p-datatable-tbody > tr > td {
        padding: 0.4rem;
        display: flex;
        width: 100%;
        border: 0 none;
        text-align: left !important;

        .p-column-title {
          width: 30%;
          display: inline-block;
          margin-right: 10px;
          font-weight: bold !important;
          font-family: 'Avenir Next Cyr', 'Helvetica Neue', Ubuntu, sans-serif !important;
          word-break: break-word;

          & + * {
            width: 70%;
          }
        }
      }
    }
  }
}

.p-datatable.basic-table {
  th,
  td {
    padding: 11px !important;
    font-weight: 500;
    font-family: 'Avenir Next Cyr', 'Helvetica Neue', sans-serif !important;
  }

  th {
    border: 1px solid #eee !important;
  }

  td {
    font-size: 14px !important;
    border-color: #2224261a !important;
  }

  td.border-left-red {
    border-left: 2px solid #d00000 !important;
  }

  td.border-left-green {
    border-left: 2px solid #009f00 !important;
  }

  tr {
    &:first-child td {
      border-top: none !important;
    }

    &:last-child td {
      border-bottom: none !important;
    }

    td:first-child {
      border-left: none !important;
    }

    td:last-child {
      border-right: none !important;
    }
  }
}

.no-results {
  padding: 2rem;
  text-align: center;
  animation: scaleIn 0.2s;

  i.pi {
    font-size: 70px;
    color: #afafaf;
  }
}

.p-inputgroup-addon {
  min-width: unset !important;
}

.p-avatar img {
  border-radius: 3px;
}

.p-toast-message-notification {
  background: rgb(73, 84, 105);
  color: white;

  .notification-text {
    color: #d9d9d9;
  }

  .p-toast-icon-close {
    color: #ffffffc2;
  }
}

.p-tag {
  padding: 0.2rem 0.7rem !important;
}

.p-card.blue-banner-manager {
  background: linear-gradient(#d8ebf8, #d0e3ef);
  border: 1px solid #72a6de;

  .p-card-content {
    color: #3b4a8b;

    a {
      color: #4c4c4c;
      text-decoration: underline;
    }
  }
}

.p-card.red-banner-manager {
  background: linear-gradient(#ffebeb, #ffd6d6);
  border: 1px solid #ff7979;

  .p-card-content {
    color: #da6565;
  }
}

.p-card.green-banner-manager {
  background: linear-gradient(#f0ffeb, #e4ffd6);
  border: 1px solid #7fd262;

  .p-card-content {
    color: #55883f;
  }
}

.p-card.beige-banner-manager {
  background: linear-gradient(#fffff1, #f3f3d5);
  border: 1px solid #d2b262;

  .p-card-content {
    color: #88713f;
  }
}

.selected-status-active {
  border-width: 0 0 2px 0 !important;
  border-style: solid;
  cursor: pointer;
  padding: 9px 12px;

  &:hover {
    background-color: rgba(100, 116, 139, 0.04) !important;
  }
}

.selected-status {
  cursor: pointer;
  padding: 9px 12px;

  &:hover {
    background-color: rgba(100, 116, 139, 0.04) !important;
  }
}

.p-datatable-header {
  padding: 0.35rem 0.25rem !important;
  background: #f5f5f5 !important;
  border-width: 1px 1px 1px 1px !important;
}

.minimized-height {
  height: 264px;
}

.p-datatable.min-size {
  th,
  td {
    padding: 5px !important;
    font-weight: 500;
    font-family: 'Avenir Next Cyr', 'Helvetica Neue', sans-serif !important;
  }

  tr > th {
    color: #1b1f3ba6 !important;
    font-weight: 700 !important;
    background: #fff !important;
    border-bottom: 1px solid #e9ecef !important;
  }

  td {
    font-size: 14px !important;
    border-color: #2224261a !important;
  }

  td.border-left-red {
    border-left: 2px solid #d00000 !important;
  }

  td.border-left-green {
    border-left: 2px solid #009f00 !important;
  }

  tr {
    &:first-child td {
      border-top: none !important;
    }

    &:last-child td {
      border-bottom: none !important;
    }

    td:first-child {
      border-left: none !important;
    }

    td:last-child {
      border-right: none !important;
    }
  }
}
