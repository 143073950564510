@import './fonts.scss';
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import "../../../node_modules/primeflex/primeflex.css";
@import "../../../node_modules/primeicons/primeicons.css";
@import '../../../node_modules/primeng/resources/themes/saga-blue/theme.css';

html {
  font-size: 14px;
}

body {
  padding: 0;
  margin: 0;
  color: #212121;
  font-family: "Avenir Next Cyr", "Helvetica Neue", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  padding: 0;
}

.app-container {
  padding: 16px 140px 0 140px;

  @media screen and (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.notfound-background-container {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url('/assets/images/original.jpeg') no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}


.main-container {
  padding: 0 140px 60px 140px;

  @media screen and (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.pb-100 {
  padding-bottom: 100% !important;
}

.fw-bold {
  font-weight: 700 !important;
  font-family: "Avenir Next Cyr", "Helvetica Neue", sans-serif !important;
}

.fw-semibold {
  font-weight: 500 !important;
  font-family: "Avenir Next Cyr", "Helvetica Neue", sans-serif !important;
}


.fw-normal {
  font-weight: normal;
  font-family: "Avenir Next Cyr", "Helvetica Neue", sans-serif !important;
}

.greytext, .greytext a {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 13px !important;
}

.p-text-red {
  color: rgba(255, 0, 0, 0.6);
}

.white-space-nowrap {
  white-space: nowrap;
}

h1, h2 {
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", sans-serif;
}

h3, h4, h5, h6 {
  font-weight: 500;
  font-family: Lato, "Helvetica Neue", sans-serif;
}

.dividing-header {
  color: #585858;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.p-calendar .p-datepicker {
  min-width: 300px !important;
}

a {
  text-decoration: none;
  color: #5892dc;
}

a:visited {
  color: #5892dc;
}

a:hover {
  color: #86aede;
}

.p-white-text {
  color: whitesmoke;
}

.cursor {
  cursor: pointer;
}

.action-menu {

  background: #fdfdfd;

  padding: 0 140px 20px 140px;

  @media screen and (max-width: 1200px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem 20px 1rem;
  }
}

.required-field {
  &::after {
    content: '*';
    margin-left: 5px;
    color: red;
  }
}

.basic-border {
  border: 1px solid #d6d6d6;
}

.divider {
  height: 1px;
  border-top: 1px solid #d6d6d6;
  margin: 10px 0 30px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.p-tag {
  font-size: 13px !important;
}

.icon-column {
  width: 40px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.columns-filter {
  .p-multiselect {
    background: transparent;
    border: none;

    .p-multiselect-trigger-icon.pi {
      font-size: 1.2rem !important;
    }
  }

  .p-multiselect-label-container {
    width: 0 !important;
  }
}

.square-icon {
  padding: 2px;
  border: 1px solid grey;
}

button {
  min-width: min-content !important;
}

.select-button-sm {
  .p-button {
    font-size: 12px;
    padding: 3px;
  }
}

.position-abs {
  position: absolute;
}

.position-rel {
  position: relative;
}

.p-flex-grow-1 {
  flex-grow: 1;
}

.p-filter-field {
  margin-bottom: 2.5rem !important;
}

.spinning {
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.tag-label {
  width: 40px;
  height: 25px;
  border-radius: 4px;
}

.report-sums-item {
  color: rgba(0, 0, 0, .6);
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover, &.active {
    color: rgba(0, 0, 0, .95);
  }
}

.ql-comment {
  padding: 0 !important;
}

.text-lg {
  font-weight: 500;
}

.max-w-25rem {
  max-width: 25rem;
}

.word-break-all {
  word-break: break-all;
}

.funnel-chart-labels {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  .funnel-chart-labels__item {
    width: 250px;
    text-align: center;
    display: flex;
    height: 52px;
    flex-direction: column;
    justify-content: center;

    a {
      color: black;

      &:hover {
        color: #444444;
      }
    }
  }
}

.trapezoid {
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top-style: solid;
}

.poly:hover {
  cursor: pointer;
  fill: red !important;
  border: 1px solid red;
}

.p-toast-bottom-right.notification-toast {
  bottom: 50px
}

.break-word {
  word-break: break-word;
}

.select-button-tooltip {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
}

.p-tabmenu .p-tabmenu-nav {
  border: none;

  .p-tabmenuitem {
    border-bottom: 2px solid var(--surface-200);

    @media screen and (max-width: 768px) {
      border: none;
    }
  }
}

.dashboard-height-1 {
  height: 200px !important;
}

.dashboard-height-2 {
  height: 400px !important;
}

.dashboard-height-3 {
  height: 600px !important;
}

.p-card {
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%) !important;

  .p-card-title {
    font-size: 1.15rem;
  }

  &.compact-card,
  &.very-compact-card {
    .p-card-body {
      padding: 0 !important;
    }

    .p-card-title {
      padding: 1.25rem 1.25rem 0 1.25rem !important;
    }
  }

  &.very-compact-card {
    .p-card-content {
      padding: 0 !important;
    }
  }

  &.problem-card {
    border: 3px solid #ff00002e !important;
  }
}

.hover\:color-red:hover {
  color: #f26868 !important;
}

.hover\:color-blue:hover {
  color: #609af8 !important;
}
