@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
  url('/assets/fonts/avenir/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextCyr-Medium.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
  url('/assets/fonts/avenir/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextCyr-Regular.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('/assets/fonts/avenir/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
  url('/assets/fonts/avenir/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/avenir/AvenirNextCyr-Demi.woff') format('woff'),
  url('/assets/fonts/avenir/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'), local('Lato-Bold'),
  url('/assets/fonts/lato/latobold.woff2') format('woff2'),
  url('/assets/fonts/lato/latobold.woff') format('woff'),
  url('/assets/fonts/lato/latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Medium'), local('Lato-Medium'),
  url('/assets/fonts/lato/latomedium.woff2') format('woff2'),
  url('/assets/fonts/lato/latomedium.woff') format('woff'),
  url('/assets/fonts/lato/latomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
